import React from "react";

const Contact = () => {
    return (
        <div>
            <h1>
                Mail us on
                feedback@geeksforgeeks.org
            </h1>
        </div>
    );
};

export default Contact;