import React from "react";
import { Nav, NavLink, NavMenu } from "./NavbarElements";

const Navbar = () => {
    return (
        <>
            <Nav>
                <NavMenu>
                    <NavLink to="/about" >
                        About
                    </NavLink>
                    <NavLink to="/contact" >
                        Contact Us
                    </NavLink>
                    <NavLink to="/blogs" >
                        Blogs
                    </NavLink>
                    <NavLink to="/sign-up" >
                        Sign Up
                    </NavLink>
                    <NavLink to="/termolog" >
                        termolog
                    </NavLink>
                    <NavLink to="/termostat" >
                        термостаты
                    </NavLink>
                </NavMenu>
            </Nav>
        </>
    );
};

export default Navbar;