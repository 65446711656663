import React, { useEffect, useRef, useState } from "react";
import Chart from "../components/Chart";
import axios from 'axios';
import { CartesianGrid, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const Termolog = () => {

    interface Row { time: String; value: number; minSanpin: number; minimum: number }

    const [data, setData] = useState<Row[]>([]);

    useEffect(() => {
        bind(288);

    }, []);

    const load = async (limit: number): Promise<any[]> => {
        let resp = await axios.get("http://tags.paranet.ru/load_tag_datas.php?id=TERM-LOG-01&limit=" + limit);
        //console.log(data);
        return resp.data
    };

    const bind = async (limit: number) => {

        let raw = await load(limit);
        //let raw: any[] = [{ time: "2025-01-06 14:03:12", value: 48 }, { time: "2025-01-06 13:58:12", value: 48.5 }, { time: "2025-01-06 13:53:12", value: 48 }, { time: "2025-01-06 13:48:12", value: 42 }, { time: "2025-01-06 13:43:12", value: 27 }, { time: "2025-01-06 13:38:12", value: 27.5 }, { time: "2025-01-06 13:33:12", value: 35 }, { time: "2025-01-06 13:28:12", value: 27.5 }, { time: "2025-01-06 13:23:12", value: 29.5 }, { time: "2025-01-06 13:18:12", value: 30 }];

        //console.log(raw);

        let rows: Row[] = [];

        for (let i = 0; i < raw.length; i++) {
            let dt = new Date(raw[i]['time']);
            let val = raw[i]['value'];

            let r: Row = {
                time: dt.getDate() + "-" + (dt.getMonth() + 1) + " " + dt.getHours() + ':' + (dt.getMinutes() < 10 ? '0' + dt.getMinutes() : dt.getMinutes()),
                value: val,
                minimum: 40,
                minSanpin: 55
            };
            rows.push(r);
        }

        setData(rows);

    };

    // const CustomTooltip = ({ active, payload, label }) => {
    //     if (active && payload && payload.length) {
    //         return (
    //             <div className="custom-tooltip">
    //                 <p className="label">{`${label} : ${payload[0].value}`}</p>
    //                 <p className="intro">{label}</p>
    //                 <p className="desc">Anything you want can be displayed here.</p>
    //             </div>
    //         );
    //     }

    //     return null;
    // };

    const clickHandler_1Day = async () => {
        bind(288);
    };
    const clickHandler_3Day = async () => {
        bind(3 * 288);
    };
    const clickHandler_7Day = async () => {
        bind(7 * 288);
    };

    return (
        <div >
            <h1>температура горячей воды </h1>
            <div>
                <button onClick={clickHandler_1Day}>Сутки</button>
                <button onClick={clickHandler_3Day}>3 дня</button>
                <button onClick={clickHandler_7Day}>Неделя</button>
            </div>
            <ResponsiveContainer width="100%" height={600}>
                <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="time" />
                    <YAxis domain={[15, 65]} />
                    <Tooltip />
                    <Legend />
                    <ReferenceLine y={40} label="min" stroke="red" />
                    <ReferenceLine y={55} label="sanpin" stroke="green" />
                    <Line type="monotone" dataKey="value" stroke="blue" fill="#8884d8" activeDot={{ r: 7 }} />
                </LineChart>
            </ResponsiveContainer>

        </div>
    );
};

export default Termolog;