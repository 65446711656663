import React from "react";
import Navbar from "./components/Navbar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./pages";
import About from "./pages/about";
import Blogs from "./pages/blogs";
import Contact from "./pages/contact";
import SignUp from "./pages/signup";
import Termostat from "./pages/termostat";
import Termolog from "./pages/termolog";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/termostat" element={<Termostat />} />
        <Route path="/termolog" element={<Termolog />} />
        <Route path="/about" element={<About />} />
        <Route
          path="/contact"
          element={<Contact />}
        />
        <Route path="/blogs" element={<Blogs />} />
        <Route
          path="/sign-up"
          element={<SignUp />}
        />
      </Routes>
    </Router>
  );
}

export default App;