import React, { useEffect, useRef, useState } from "react";
import Chart from "../components/Chart";
import axios from 'axios';
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ReferenceLine, Line, AreaChart, Area, Brush } from "recharts";

const Termostat = () => {

    interface Row { time: string; value: number; }

    const [dataT1, setDataT1] = useState<Row[]>([]);
    const [dataT2, setDataT2] = useState<Row[]>([]);
    const [dataT3, setDataT3] = useState<Row[]>([]);

    useEffect(() => {
        //console.log('useEffect');
        bind();
    }, []);

    const load = async (num: number, limit: number): Promise<any[]> => {
        let resp = await axios.get("http://tags.paranet.ru/load_tag_datas.php?id=STAT-TERM-STAT-0" + num + "&limit=" + limit);
        //console.log(data);
        return resp.data
    };

    const addMinutes = (date: Date, minutes: number): Date => {
        return new Date(date.getTime() + minutes * 60000);
    };


    //const getRows = async (num: number): Promise<Row[]> => {
    const convert = async (raw: any[], begin: Date, end: Date): Promise<Row[]> => {
        //let raw = await load(num, 100);
        let dt_first = new Date(raw[0]['time']);
        let dt_start = new Date(dt_first.getFullYear(), dt_first.getMonth(), dt_first.getDate(), dt_first.getHours(), dt_first.getMinutes(), 0);

        //let dt = dt_start;
        let dt = begin;
        let rows: Row[] = [];
        for (let i = 0; i < raw.length; i++) {
            let dt_raw = new Date(raw[i]['time']);
            let dt_next = new Date(dt_raw.getFullYear(), dt_raw.getMonth(), dt_raw.getDate(), dt_raw.getHours(), dt_raw.getMinutes(), 0);
            while (dt < dt_next) {
                let r: Row = {
                    time: dt.getHours() + ':' + (dt.getMinutes() < 10 ? '0' + dt.getMinutes() : dt.getMinutes()),
                    value: raw[i]['value']
                };
                rows.push(r);
                dt = addMinutes(dt, 1);
            }
        }
        while (dt < end) {
            let r: Row = {
                time: dt.getHours() + ':' + (dt.getMinutes() < 10 ? '0' + dt.getMinutes() : dt.getMinutes()),
                value: raw[raw.length - 1]['value']
            };
            rows.push(r);
            dt = addMinutes(dt, 1);
        }

        return rows;
    };

    const bind = async () => {

        let raw1 = await load(1, 100);
        let raw2 = await load(2, 100);
        let raw3 = await load(3, 100);

        let time_begin = new Date(Math.max(...[(new Date(raw1[0].time)).getTime(), (new Date(raw2[0].time)).getTime(), (new Date(raw3[0].time)).getTime()]));
        //let time_end = new Date(Math.min(...[(new Date(raw1[raw1.length - 1].time)).getTime(), (new Date(raw2[raw2.length - 1].time)).getTime(), (new Date(raw3[raw3.length - 1].time)).getTime()]));
        let time_end = new Date();

        let rows1 = await convert(raw1, time_begin, time_end);
        setDataT1(rows1);

        let rows2 = await convert(raw2, time_begin, time_end);
        setDataT2(rows2);

        let rows3 = await convert(raw3, time_begin, time_end);
        setDataT3(rows3);
    };

    return (
        <div>
            <h1>Веранда</h1>Время работы: {((dataT1.filter(x => x.value > 0)).length / 60).toFixed(1)} часов | {((dataT1.filter(x => x.value > 0)).length / dataT1.length * 100).toFixed(1)}%
            <ResponsiveContainer width="100%" height={200}>
                <AreaChart
                    width={500}
                    height={300}
                    data={dataT1}
                    syncId="anyId"
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="time" />
                    <YAxis domain={[0, 1]} />
                    <Tooltip />
                    <Area type="monotone" dataKey="value" stroke="blue" fill="#8884d8" activeDot={{ r: 1 }} />
                </AreaChart>
            </ResponsiveContainer>

            <h1>этаж 1</h1>Время работы: {((dataT3.filter(x => x.value > 0)).length / 60).toFixed(1)} часов | {((dataT3.filter(x => x.value > 0)).length / dataT3.length * 100).toFixed(1)}%
            <ResponsiveContainer width="100%" height={200}>
                <AreaChart
                    width={500}
                    height={300}
                    data={dataT3}
                    syncId="anyId"
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="time" />
                    <YAxis domain={[0, 1]} />
                    <Tooltip />
                    <Area type="monotone" dataKey="value" stroke="blue" fill="#8884d8" activeDot={{ r: 1 }} />
                </AreaChart>
            </ResponsiveContainer>

            <h1>этаж 2</h1>Время работы: {((dataT2.filter(x => x.value > 0)).length / 60).toFixed(1)} часов | {((dataT2.filter(x => x.value > 0)).length / dataT2.length * 100).toFixed(1)}%
            <ResponsiveContainer width="100%" height={200}>
                <AreaChart
                    width={500}
                    height={300}
                    data={dataT2}
                    syncId="anyId"
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="time" />
                    <YAxis domain={[0, 1]} />
                    <Tooltip />
                    <Area type="monotone" dataKey="value" stroke="blue" fill="#8884d8" activeDot={{ r: 1 }} />
                    <Brush />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

export default Termostat;